import React, { useState } from "react";
import { Row, Button, CardBody, Col } from "reactstrap";

import Modal from "../../../components/Common/Modal";
import ConfirmationModal from "./ConfirmationModal";

const ListingModal = (props) => {
  const { isOpen, toggleModal, match, currentState, setState } = props;
  const [openConfirmationSimple, setOpenConfirmationSimple] = useState(null);
  const [openConfirmationShipping, setOpenConfirmationShipping] = useState(null);

  const openListingModal = (comfirmationState, setConfirmationState) => {
    toggleModal(match?._id, currentState, setState);
    toggleModal(match?._id, comfirmationState, setConfirmationState);
  };

  return (
    <>
      <Modal
        size="md"
        isOpen={isOpen}
        toggle={() => toggleModal(match?._id, currentState, setState)}
        style={{ width: "100%", maxWidth: "534px" }}
      >
        <div className="modal-header pt-3 pb-2">
          <h4 className="modal-title text-secondary" id="myLargeModalLabel">
            Product Listing
          </h4>
          <button
            onClick={() => toggleModal(match?._id, currentState, setState)}
            type="button"
            className="close text-white"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i aria-hidden="true" className="bx bx-md bx-x"></i>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="px-2">
            <CardBody className="p-2">
              <h5>List As</h5>
              <Row>
                <Col className="px-2">
                  <Button
                    color="link"
                    className="text-white my-2 p-0"
                    style={{ textDecoration: "none" }}
                    onClick={() => openListingModal(openConfirmationShipping, setOpenConfirmationShipping)}
                  >
                    <div
                      className="border rounded text-center d-flex flex-column justify-content-center align-items-center"
                      style={{ width: "231px", height: "126px" }}
                    >
                      <i className="bx bx-cube bx-lg text-primary cursor-pointer"></i>
                      <p className="mb-0">Product Shipping Item</p>
                    </div>
                  </Button>
                </Col>
                <Col className="px-2">
                  <Button
                    color="link"
                    className="text-white my-2 p-0"
                    style={{ textDecoration: "none" }}
                    onClick={() => openListingModal(openConfirmationSimple, setOpenConfirmationSimple)}
                  >
                    <div
                      className="border rounded text-center d-flex flex-column justify-content-center align-items-center"
                      style={{ width: "231px", height: "126px" }}
                    >
                      <i className="bx bx-list-ul bx-lg text-primary cursor-pointer"></i>
                      <p className="mb-0">Simple Listing</p>
                    </div>
                  </Button>
                </Col>
              </Row>
            </CardBody>
            <Row className="align-items-center d-flex justify-content-end px-2 py-3">
              <Button
                color="primary "
                outline
                className="waves-effect waves-light mr-3"
                onClick={() => toggleModal(match?._id, currentState, setState)}
              >
                Cancel
              </Button>
            </Row>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={openConfirmationSimple === match._id}
        toggleModal={toggleModal}
        currentState={openConfirmationSimple}
        setState={setOpenConfirmationSimple}
        match={match}
        dispatchAction={(data) => {}}
        message="This item will be listed."
        buttonMsg="List Match"
      />
      <ConfirmationModal
        isOpen={openConfirmationShipping === match._id}
        toggleModal={toggleModal}
        currentState={openConfirmationShipping}
        setState={setOpenConfirmationShipping}
        match={match}
        dispatchAction={(data) => {}}
        message="This match will be listed as a drop shipping item."
        buttonMsg="List Match"
      />
    </>
  );
};

export default ListingModal;
