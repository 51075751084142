import * as types from "./actionTypes";
import { get, set, merge, cloneDeep } from "lodash";

const initialState = {
  error: "",
  success: "",
  loading: false,
  filters: {},
  appliedFilters: {},
  savedFilters: {},
  selectedFilter: "",
  selectedMarketplace: "",
  columnFilters: {
    amazon: {
      profit: {
        displayOption: "Greater Than",
        option: ">",
        title: "Profit",
        value: "0",
      },
    },
    walmart: {
      profit: {
        displayOption: "Greater Than",
        option: ">",
        title: "Profit",
        value: "0",
      },
    },
  },
  salesRank: {
    value: "Last Captured",
    label: "Last Captured Value",
    name: "salesRank",
  },
  totalCount: 0,
  page: 0,
  matches: [],
};

const defaultSalesRank = {
  value: "Last Captured",
  label: "Last Captured Value",
  name: "salesRank",
};

const profitAnalyzer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MATCHES:
      if (action.payload?.page)
        state = { ...state, salesRank: defaultSalesRank, loading: true, error: "", success: "" };
      else
        state = { ...state, page: 0, matches: [], salesRank: defaultSalesRank, loading: true, error: "", success: "" };
      break;
    case types.FETCH_MATCHES_SUCCESS:
      if (state.page === 0) {
        state = {
          ...state,
          loading: false,
          appliedFilters: cloneDeep(state.filters),
          matches: action.payload.data || [],
          totalCount: action.payload.totalCount || 0,
          error: "",
          success: "",
        };
      } else {
        state = {
          ...state,
          loading: false,
          matches: [...state.matches, ...action.payload.data],
          totalCount: action.payload.totalCount || 0,
          error: "",
          success: "",
        };
      }
      break;
    case types.ADD_MISMATCH_PRODUCT:
      state = { ...state, loading: true, error: "", success: "" };
      break;
    case types.ADD_MISMATCH_PRODUCT_SUCCESS:
      state = { ...state, loading: false, success: "Product Added to Mismatch Successfully" };
      break;
    case types.FETCH_SAVED_FILTERS:
      state = { ...state, loading: true, error: "", success: "" };
      break;
    case types.ADD_NEW_SAVED_FILTER:
      state = { ...state, loading: true, error: "", success: "" };
      break;
    case types.UPDATE_SAVED_FILTER:
      state = { ...state, loading: true, error: "", success: "", selectedFilter: "" };
      break;
    case types.UPDATE_SAVED_FILTER_SUCCESS:
      state = { ...state, loading: false, success: "Saved Filter Updated Successfully" };
      break;
    case types.SET_SALES_RANK:
      state = { ...state, salesRank: action.payload };
      break;
    case types.FETCH_SAVED_FILTERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        savedFilters: action.payload.savedFilters,
        filters: action.payload.filters,
        selectedMarketplace: action.payload.defaultMarketplace || "amazon",
        error: "",
        success: "",
      };
      break;
    case types.ADD_NEW_SAVED_FILTER_SUCCESS:
      state = {
        ...state,
        savedFilters: {
          ...state.savedFilters,
          [action.payload.destination]: {
            ...state.savedFilters[action.payload.destination],
            [action.payload.name]: action.payload.filter,
          },
        },
        success: "Saved Filter Added Successfully",
        loading: false,
      };
      break;
    case types.SET_SAVED_FILTERS: {
      const { selectedFilter } = action.payload;
      const currentMarketplace = state.selectedMarketplace;
      const newFilter = state.savedFilters?.[currentMarketplace]?.[selectedFilter];
      state = {
        ...state,
        filters: {
          ...state.filters,
          [currentMarketplace]: merge({}, state.filters[currentMarketplace], newFilter),
        },
        selectedFilter,
      };
      break;
    }
    case types.SET_MARKETPLACE:
      state = { ...state, selectedMarketplace: action.payload.marketplace };
      break;

    case types.SET_FILTER: {
      const { marketplace, filterSection, filterSubSection, controlType, filterValue, optionType } = action.payload;
      const path = ["filters", marketplace, filterSection, "controls", filterSubSection];
      if (optionType) path.push("options", optionType);
      let newState = cloneDeep(state);
      if (controlType === "dropdown") {
        newState = set(newState, [...path, "value"], filterValue);
      } else if (controlType === "checkbox") {
        const currentChecked = get(state, [...path, "checked"], false);
        newState = set(newState, [...path, "checked"], !currentChecked);
      }
      state = { ...newState, columnFilters: state.columnFilters };
      break;
    }

    case types.ADD_COLUMN_FILTER: {
      const marketPlace = action.payload.marketPlace;
      state = {
        ...state,
        columnFilters: {
          ...state.columnFilters,
          [marketPlace]: {
            ...state.columnFilters[marketPlace],
            [action.payload.column]: {
              option: action.payload.option,
              value: action.payload.value,
              title: action.payload.title,
              displayOption: action.payload.displayOption,
            },
          },
        },
      };
      break;
    }

    case types.REMOVE_COLUMN_FILTER: {
      const marketPlace = action.payload.marketPlace;
      const { [action.payload.column]: removed, ...remainingFilters } = state.columnFilters[marketPlace];
      state = { ...state, columnFilters: { ...state.columnFilters, [marketPlace]: remainingFilters } };
      break;
    }

    case types.CHECK_PRODUCT_ELIGIBILITY:
      state = {
        ...state,
        matches: state.matches.map((match) => (match._id === action.payload._id ? { ...match, loading: true } : match)),
      };
      break;
    case types.CHECK_PRODUCT_ELIGIBILITY_SUCCESS: {
      const eligibility = action.payload.isRestricted ? "restricted" : "eligible";
      state = {
        ...state,
        matches: state.matches.map((match) =>
          match._id === action.payload._id ? { ...match, loading: false, eligibility } : match,
        ),
      };
      break;
    }
    case types.SET_HIDE_ROW:
      state = {
        ...state,
        matches: state.matches.map((match) => (match._id === action.payload._id ? { ...match, hide: true } : match)),
      };
      break;
    case types.SET_PAGE_NUMBER:
      state = { ...state, page: action.payload.page };
      break;

    case types.API_ERROR:
      state = { ...state, error: action.payload, success: false, loading: false };
      break;
    default:
      break;
  }
  return state;
};
export default profitAnalyzer;
