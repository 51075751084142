import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardTitle, Spinner, Nav, NavItem, NavLink } from "reactstrap";
import Chart from "react-apexcharts";
import Flatpickr from "react-flatpickr";
import getBrandImageSrc from "../../../../utils/brandImages";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/repricer/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { map, maxBy, get, orderBy } from "lodash";
import { RenderIf, adjustDate, amountText } from "../../../../utils/common";
import classnames from "classnames";

const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";

const BrandLogo = ({ brand }) => {
  return (
    <div className="d-inline-flex align-items-center">
      <img src={getBrandImageSrc(brand)} alt={`#${brand} logo`} />
      <span className="text-capitalize">{brand}</span>
    </div>
  );
};

const DaysButtonWrapper = ({ days, setDaysLimit, daysLimit, setDateRange, key }) => {
  return (
    <Nav pills className="navtab-bg nav-justified dashboard-navabr mb-2 mb-md-0">
      <NavItem key={"_col_" + key}>
        <NavLink
          style={{ cursor: "pointer", minWidth: "96px", height: "30px" }}
          className={classnames({ active: daysLimit === days })}
          onClick={() => {
            setDaysLimit(days);
            setDateRange([moment(moment().subtract(days, "days")).format(dateFormat), moment().format(dateFormat)]);
          }}
        >
          {days} Days
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default function PriceChangeHistory () {
  const [daysLimit, setDaysLimit] = useState(7);
  const [dateRange, setDateRange] = useState([
    moment(moment().subtract(7, "days")).format(dateFormat),
    moment().format(dateFormat),
  ]);
  const dispatch = useDispatch();
  const { priceChangeHistories, loading } = useSelector((state) => state.Repricer);
  const { appTheme } = useSelector((state) => state.Layout);
  const { platform, itemId, mpAccountId, sku } = useParams();

  useEffect(() => {
    dispatch(
      actions.fetchRepricerPriceChangeHistories({
        platform: platform === "amazon" && platform,
        search: sku,
        account: mpAccountId,
        start_date: dateRange?.[0],
        end_date: dateRange?.[1],
        priceChangeHistory: true,
      }),
    );
  }, [platform, itemId, daysLimit]);

  const getDaysLimit = (dates) => {
    if (dates.length > 1) return moment(dates[1]).diff(moment(dates[0]), "days");
  };

  const results = useMemo(() => {
    return orderBy(priceChangeHistories?.results, (item) => new Date(item.time), ["asc"]);
  }, [priceChangeHistories?.results]);

  const [maxPrice, series] = useMemo(
    () => [
      get(maxBy(results, "new_price"), "new_price"),
      [
        { name: "Price", data: map(results, (item) => item.new_price) },
      ],
    ],
    [results],
  );

  const createTooltipRow = ({ label, value, color, isAmount = true }) => {
    return `
      <div style="margin-bottom: 8px; display: flex; justify-content: space-between;">
        <span style="color: ${color}; font-size: 14px;">${label}:</span>
        <span style="font-weight: bold; font-size: 14px;">${isAmount ? amountText(value) : value ?? "N/A"}</span>
      </div>
    `;
  };

  const data = {
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      tooltip: {
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const point = results[dataPointIndex];
          return `
      <div style="background-color: ${appTheme === "light" ? "#ffffff" : "#222737"}; border-radius: 8px; padding: 18px; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); font-family: Arial, sans-serif; width: 300px;">
        <p style="margin: 0 0 12px; font-size: 14px; color:${appTheme === "light" ? " #555" : "#a6b0cf"};        ; font-weight: bold;">
          ${moment(point.time).format("DD MMM HH:mm")}
        </p>
        <hr />
        ${[
          { label: "New Price", value: point.new_price, color: "#008FFB" },
          { label: "Old Price", value: point.old_price, color: "#28a745" },
          { label: "Strategy", value: point.strategy_name, color: "#ffc107", isAmount: false },
          { label: "Cost", value: point.cost, color: "#dc3545" },
          { label: "Min Price", value: point.minimum_price, color: "#6f42c1" },
          { label: "Max Price", value: point.maximum_price, color: "#17a2b8" },
        ]
          .map(createTooltipRow)
          .join("")}
      </div>
    `;
        },
      },
      xaxis: {
        categories: map(results, (item) => moment(item.time).format("DD MMM HH:mm")),
        labels: {
          format: "HH:mm",
        },
      },
      yaxis: [
        {
          min: 0,
          max: maxPrice,
          labels: {
            formatter: function (value) {
              return amountText(value);
            },
          },
        },
      ],
      stroke: { curve: "straight" },
    },
    series,
  };

  return (
    <Card>
      <CardBody className="p-4">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
          <CardTitle tag="h5">Price history</CardTitle>
          <div
            style={loading ? { pointerEvents: "none", cursor: "not-allowed" } : {}}
            className="d-flex flex-column flex-md-row align-items-center gap-3"
          >
            <div className="d-inline-flex align-items-center gap-1">
              <BrandLogo brand={platform} />
            </div>
            {[365, 180, 30, 7].map((day) => (
              <DaysButtonWrapper
                key={day}
                days={day}
                setDaysLimit={setDaysLimit}
                daysLimit={daysLimit}
                setDateRange={setDateRange}
                dateRange={dateRange}
              />
            ))}
            <Flatpickr
              className="w-230 form-control"
              placeholder="Custom Range"
              value={dateRange}
              options={{ maxDate: new Date(), mode: "range" }}
              onChange={(dates) => {
                if (dates.length < 2) return;

                const startdate = moment(adjustDate(dates[0]));
                let endDate = moment(adjustDate(dates[1]));

                if (startdate.isSame(endDate)) {
                  endDate = endDate.endOf("day");
                }

                setDateRange([startdate.format(dateFormat), endDate.format(dateFormat)]);
                setDaysLimit(getDaysLimit(dates));
              }}
            />
          </div>
        </div>
        <RenderIf
          isTrue={!loading}
          fallback={
            <center className="my-5">
              <Spinner className="my-5" color="primary" style={{ width: "36px", height: "36px" }} />
            </center>
          }
        >
          <Chart options={data.options} series={data.series} height={350} loading />
        </RenderIf>
      </CardBody>
    </Card>
  );
}
