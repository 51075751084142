import * as types from "./actionTypes";

export const getBuyboxStats = (params) => ({ type: types.BUYBOX_STATS, payload: params });
export const getBuyboxStatsSuccess = (data) => ({ type: types.BUYBOX_STATS_SUCCESS, payload: data });

export const fetchListings = (params) => ({ type: types.FETCH_REPRICER_LISTINGS, payload: params });
export const fetchListingsSuccess = (data) => ({ type: types.FETCH_REPRICER_LISTINGS_SUCCESS, payload: data });

export const fetchUploadFileHistory = (params) => ({ type: types.FETCH_UPLOAD_FILE_HISTORY, payload: params });
export const fetchUploadFileHistorySuccess = (data) => ({ type: types.FETCH_UPLOAD_FILE_HISTORY_SUCCESS, payload: data });

export const fetchListingsFormulas = (selectedPlatform, params) => ({ type: types.FETCH_REPRICER_LISTINGS_FORMULAS, payload: { selectedPlatform, params } });
export const fetchListingsFormulasSuccess = (data) => ({ type: types.FETCH_REPRICER_LISTINGS_FORMULAS_SUCCESS, payload: data });

export const fetchRepricerListingDetails = (selectedPlatform, id, mpAccountId) => ({ type: types.FETCH_REPRICER_LISTING_DETAILS, payload: { selectedPlatform, id, mpAccountId } });
export const fetchRepricerListingDetailsSuccess = (payload) => ({ type: types.FETCH_REPRICER_LISTING_DETAILS_SUCCESS, payload });

export const fetchRepricerProductLogs = (payload) => ({ type: types.FETCH_REPRICER_PRODUCT_LOGS, payload });
export const fetchRepricerProductLogsSuccess = (payload) => ({ type: types.FETCH_REPRICER_PRODUCT_LOGS_SUCCESS, payload });

export const fetchRepricerPriceChangeHistories = (payload) => ({ type: types.FETCH_REPRICER_PRICE_CHANGE_HISTORIES, payload });
export const fetchRepricerPriceChangeHistoriesSuccess = (payload) => ({ type: types.FETCH_REPRICER_PRICE_CHANGE_HISTORIES_SUCCESS, payload });

export const createListingsFormulas = (selectedPlatform, params) => ({ type: types.CREATE_REPRICER_LISTINGS_FORMULAS, payload: { selectedPlatform, params } });
export const createListingsFormulasSuccess = (data) => ({ type: types.CREATE_REPRICER_LISTINGS_FORMULAS_SUCCESS, payload: data });

export const updateListingsFormulas = (selectedPlatform, id, params) => ({ type: types.UPDATE_REPRICER_LISTINGS_FORMULAS, payload: { selectedPlatform, id, params } });
export const updateListingsFormulasSuccess = (data) => ({ type: types.UPDATE_REPRICER_LISTINGS_FORMULAS_SUCCESS, payload: data });

export const deleteListingsFormula = (selectedPlatform, id) => ({ type: types.DELETE_REPRICER_LISTINGS_FORMULA, payload: { selectedPlatform, id } });
export const deleteListingsFormulaSuccess = (data) => ({ type: types.DELETE_REPRICER_LISTINGS_FORMULA_SUCCESS, payload: { data } });

export const createListingsTags = (selectedPlatform, params, resetFilters) => ({ type: types.CREATE_REPRICER_LISTINGS_TAGS, payload: { selectedPlatform, params, resetFilters } });
export const createListingsTagsSuccess = (data) => ({ type: types.CREATE_REPRICER_LISTINGS_TAGS_SUCCESS, payload: data });

export const updateListingsTags = (selectedPlatform, id, params) => ({ type: types.UPDATE_REPRICER_LISTINGS_TAGS, payload: { selectedPlatform, id, params } });
export const updateListingsTagsSuccess = (data) => ({ type: types.UPDATE_REPRICER_LISTINGS_TAGS_SUCCESS, payload: data });

export const createAndUpdateTag = (data) => ({ type: types.CREATE_AND_UPDATE_TAG, payload: { ...data } });

export const deleteListingsTag = (selectedPlatform, id, resetFilters) => ({
  type: types.DELETE_REPRICER_LISTINGS_TAG,
  payload: { selectedPlatform, id, resetFilters },
});
export const deleteListingsTagSuccess = (data) => ({ type: types.DELETE_REPRICER_LISTINGS_TAG_SUCCESS, payload: { data } });

export const deleteAllTags = (selectedPlatform, params, resetFilters) => ({
  type: types.DELETE_ALL_TAGS,
  payload: { selectedPlatform, params, resetFilters },
});
export const deleteAllTagsSuccess = (data) => ({ type: types.DELETE_ALL_TAGS_SUCCESS, payload: data });

export const uploadCSVListings = (payload) => ({ type: types.BULK_UPDATE_CSV_LISTINGS, payload });
export const uploadCSVListingsSuccess = (message) => ({
  type: types.BULK_UPDATE_CSV_LISTINGS_SUCCESS,
  payload: message,
});

export const fetchListingsStats = (params) => ({ type: types.FETCH_LISTINGS_STATS, payload: params });
export const fetchListingsStatsSuccess = (data) => ({ type: types.FETCH_LISTINGS_STATS_SUCCESS, payload: data });

export const updateListing = (payload) => ({ type: types.UPDATE_REPRICER_LISTING, payload });
export const updateListingSuccess = (payload) => ({ type: types.UPDATE_REPRICER_LISTING_SUCCESS, payload });

export const updateMapPriceListing = (payload) => ({ type: types.UPDATE_LISTING_MAP_PRICE, payload });
export const updateMapPriceListingSuccess = (payload) => ({ type: types.UPDATE_LISTING_MAP_PRICE_SUCCESS, payload });

export const getTags = (selectedPlatform, params) => ({ type: types.GET_TAGS, payload: { selectedPlatform, params } });
export const getTagsSuccess = (message) => ({ type: types.GET_TAGS_SUCCESS, payload: message });

export const assignStrategy = (selectedPlatform, data) => ({ type: types.ASSIGN_STRATEGY, payload: { selectedPlatform, data } });
export const assignStrategySuccess = (message) => ({ type: types.ASSIGN_STRATEGY_SUCCESS, payload: message });

export const assignListingsStrategy = (data, params) => ({ type: types.ASSIGN_LISTINGS_STRATEGY, payload: { data, params } });
export const assignListingsStrategySuccess = (message) => ({ type: types.ASSIGN_LISTINGS_STRATEGY_SUCCESS, payload: message });

export const getAllRepricerAccounts = (selectedPlatform, params) => ({ type: types.GET_ALL_REPRICER_ACCOUNTS, payload: { selectedPlatform, params } });
export const getAllRepricerAccountsSuccess = (message) => ({ type: types.GET_ALL_REPRICER_ACCOUNTS_SUCCESS, payload: message });

export const getStrategies = (selectedPlatform, params) => ({ type: types.GET_STRATEGIES, payload: { selectedPlatform, params } });
export const getStrategiesSuccess = (message) => ({ type: types.GET_STRATEGIES_SUCCESS, payload: message });

export const getStrategiesStats = (selectedPlatform, params) => ({ type: types.GET_STRATEGIES_STATS, payload: { selectedPlatform, params } });
export const getStrategiesStatsSuccess = (message) => ({ type: types.GET_STRATEGIES_STATS_SUCCESS, payload: message });

export const createStrategy = (selectedPlatform, data) => ({ type: types.CREATE_STRATEGY, payload: { selectedPlatform, data } });
export const createStrategySuccess = (message) => ({ type: types.CREATE_STRATEGY_SUCCESS, payload: message });

export const updateStrategy = (selectedPlatform, id, params) => ({ type: types.UPDATE_STRATEGY, payload: { selectedPlatform, id, params } });
export const updateStrategySuccess = (message) => ({ type: types.UPDATE_STRATEGY_SUCCESS, payload: message });

export const removeStrategy = (selectedPlatform, id) => ({ type: types.REMOVE_STRATEGY, payload: { selectedPlatform, id } });
export const removeStrategySuccess = (message) => ({ type: types.REMOVE_STRATEGY_SUCCESS, payload: message });

export const removeStrategyAccount = (selectedPlatform, id) => ({ type: types.REMOVE_STRATEGY_ACCOUNT, payload: { selectedPlatform, id } });
export const removeStrategyAccountSuccess = (message) => ({ type: types.REMOVE_STRATEGY_ACCOUNT_SUCCESS, payload: message });

export const setProfitDashboardTrialModal = (bool) => ({ type: types.SET_PROFIT_DASHBOARD_TRIAL_MODAL, payload: bool });
export const setRepricerFormulasModal = (bool) => ({ type: types.SET_REPRICER_FORMULAS_MODAL, payload: bool });
export const setRepricerTagsModal = (bool) => ({ type: types.SET_REPRICER_TAGS_MODAL, payload: bool });
export const setRepricerListingsModal = (bool) => ({ type: types.SET_REPRICER_LISTINGS_MODAL, payload: bool });
export const setStrategyModal = (bool) => ({ type: types.SET_STRATEGY_MODAL, payload: bool });
export const setUpdateCOGSModal = (bool) => ({ type: types.SET_UPDATE_COGS_MODAL, payload: bool });
export const setAssignStrategyModal = (bool) => ({ type: types.SET_ASSIGN_STRATEGY_MODAL, payload: bool });
export const setRemoveStrategyModal = (bool) => ({ type: types.SET_REMOVE_STRATEGY_MODAL, payload: bool });

export const apiError = (error) => ({ type: types.API_ERROR, payload: error });
export const removeStrategyError = (error) => ({ type: types.REMOVE_STRATEGY_ERROR, payload: error });
